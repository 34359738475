@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700&display=swap");

:root {
    --blue-200: #a4b5e2;
    --blue-400: #6584c4;
    --blue-600: #06a0c9;
    --blue-800: #01158b;
    --red-400: #e72419;
    --red-600: #b24d64;

    --bg-gray: #f6f6f6;
    --bg-yellow: rgb(255, 255, 117);
    --fc-light: rgb(73, 73, 73);
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Roboto", sans-serif;
    scroll-behavior: smooth;
}

a {
    text-decoration: none;
    color: var(--fc-light);
}

a:hover {
    text-decoration: underline;
}

.bg-blue {
    background-color: var(--blue-200);
    padding: 3rem 0;
}

/* Header  */

.header img {
    max-width: 12rem;
    height: auto;
    margin: 1rem 2rem 1rem 0;
}

.header-links {
    display: flex;

    margin-left: auto;
}

.header-link {
    text-decoration: none;
    margin-right: 0rem;
}

.appbar-img-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.app-bar-responsive img {
    width: max(5rem, 10vw);
    height: auto;
    margin: 0.5em 0;
}

.logo-uk {
    transform: scale(0.5);
}

.hero {
    /* background-image: url("../src/assets/theCar.jpg"); */
    /* background-size: cover; */
    display: grid;
    grid-template-columns: 3fr 2fr;
    grid-template-columns: repeat(auto-fit, minmax(10rem, 1fr));
    justify-content: space-between;
    padding: 3em 1em;
    margin-bottom: 2em;
}

.hero p {
    font-weight: bold;
}

.hero :nth-child(1) p {
    color: #e0e0e0;
    font-size: 8vw;
}
.hero :nth-child(2) {
    align-self: start;
}

.hero :nth-child(2) p {
    text-align: end;
    color: white;
    font-size: 3vw;
}

.home-summary {
    display: grid;
    grid-template-columns: 3fr 2fr;
    grid-template-columns: repeat(auto-fit, minmax(10rem, 1fr));
    gap: 2rem;
}

.home-summary img {
    width: max(300px, 30vw);
    height: auto;
}

.home-summary-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 35em;
}

.home-summary p {
    margin: 0.5em 0;

    color: var(--fc-light);
}
.home-summary-info h2 {
    font-size: max(2rem, 2.5vw);
    text-align: center;
    margin-bottom: 0.75em;
}

.home-summary-info div {
    display: flex;
    margin: 2em auto;
}
.home-summary-info div p {
    margin-right: 2rem;
}

.home-summary-img-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.schedule {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-columns: repeat(auto-fit, minmax(10rem, 1fr));
    gap: 1rem;
    margin: 2rem 0;
}

.schedule img {
    width: 100%;
    height: auto;
}

.itinerary {
    display: flex;
    justify-content: center;
    align-items: center;
}

/* .itinerary img {
    width: max(300px, 25vw);
} */

/* Updates  */

.updates-headline {
    font-size: 4rem;
    text-align: center;
    margin-bottom: 0.5em;
}

.updates {
    display: grid;
    grid-template-columns: 3fr 4fr;
    grid-template-columns: repeat(auto-fit, minmax(10rem, 1fr));
    gap: 2rem;
}

.updates h3 {
    margin-bottom: 1em;
}

.latest-news {
    padding: 0 1rem;
}
.latest-news p {
    color: var(--fc-light);
    margin: 0.75rem 0;
}
.date {
    font-size: 0.8rem;
    text-align: start;
    font-weight: bold;
}

.latest-photos {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.latest-photo-main {
    width: 100%;
    height: auto;
    margin-bottom: 1em;
}

.latest-photos-reel {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 1rem;
}

/* .latest-photos-reel img {
    max-width: calc(25% - 1rem);
} */

.latest-photos-button {
    display: flex;
    justify-content: center;
    align-items: center;
}

.info-kd {
    display: grid;
    grid-template-columns: 4fr 3fr;
    grid-template-columns: repeat(auto-fit, minmax(10rem, 1fr));
    gap: 2rem;
    margin: 3rem 0;
}

.info-kd img {
    width: 100%;
    height: auto;
}

.info-kd p {
    margin: 1rem 0;
    line-height: 1.3em;
    color: var(--fc-light);
}

/* Sponsors */

.sponsors {
    margin: 3rem;
}

.sponsors h2 {
    font-size: max(4vw, 2rem);
    margin-bottom: 0.5em;
    text-align: center;
}

.sponsorlogo {
    width: 100%;
    height: auto;
}

.footer {
    display: flex;
    flex-direction: column;
    padding: 1rem 0;
    background-color: var(--bg-gray);
}

.footer h4 {
    color: var(--fc-light);
    font-size: 1.5rem;
    margin-bottom: 0.5em;
}

.footer-links-container {
    display: flex;
    gap: 2em;
    margin: 1em 0;
}

.footer-links {
    display: flex;
    flex-direction: column;
}

.footer-links a {
    margin: 0.5em 0;
}

/* Photo Gallery  */

.photos {
    margin: 3rem 0;
}

.gallery {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(12rem, 1fr));
    gap: 2rem;
    margin: 3rem;
}

.modal-image-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.gallery-photo {
    width: 350px;
    height: auto;
}

.video-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 2rem 0;
}

/* News and Updates  */

.news-container {
    margin: 2rem 0;
}

.news-item {
    margin: 1em 0;
    color: var(--fc-light);
}

/* Volunteer  */

.volunteer {
    margin: 3rem 0;
}

.volunteer p {
    margin: 0.5em 0;
    max-width: 75ch;
}

.volunteer-text-inputs {
    display: grid;
    grid-template-columns: 1fr;
    gap: 1em;
    margin: 1em;
}

.volunteer-checkboxes label {
    margin-left: 1rem;
}

.volunteer-checkboxes {
    margin-bottom: 1em;
}

.form-volunteer * {
    max-width: 35ch;
}

@media screen and (max-width: 1000px) {
    *:not(.latest-photos-reel) {
        grid-template-columns: 1fr !important;
    }
}
